import {
  limit,
  collection,
  onSnapshot,
  orderBy,
  query,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  IconButton,
  CircularProgress,
  Snackbar,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "../config/firebase";
import { FC, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

type LineBot = {
  id: string;
  name: string;
  content: string;
  status: number;
  updatedAt: any;
};

export const LinebotListPage: FC = () => {
  const navigate = useNavigate();
  const [lineBots, setLineBots] = useState<LineBot[]>([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    const fetchLineBots = () => {
      setLoading(true);
      const lineBotCollectionRef = collection(db, "linebots");
      const q = query(lineBotCollectionRef, orderBy("updatedAt", "desc"), limit(itemsPerPage));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.empty) {
          console.log("LINE Botが見つかりません");
        } else {
          const newLineBots: LineBot[] = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            content: doc.data().content,
            status: doc.data().status,
            updatedAt: doc.data().updatedAt,
          }));
          setLineBots(newLineBots);
        }
        setLoading(false);
      });
      return () => unsubscribe();
    };

    fetchLineBots();
  }, [page]);

  const handleDeleteLineBot = async (lineBotId: string) => {
    try {
      const lineBotRef = doc(db, "linebots", lineBotId);

      // Firestoreからドキュメントを削除
      await deleteDoc(lineBotRef);

      // 現在のLINE Botリストから削除したLINE Botを除外
      setLineBots((prevLineBots) => prevLineBots.filter((lineBot) => lineBot.id !== lineBotId));

      setMessage("LINE Botを削除しました");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("LINE Botの削除に失敗しました", error);
      setMessage("LINE Botの削除に失敗しました");
      setSnackbarOpen(true);
    }
  };

  const handleEditLineBot = (lineBotId: string) => {
    navigate(`/linebot/edit/${lineBotId}`);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box sx={{ width: "80%", m: "auto", p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Typography variant="h5">登録済みのLINE Bots</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate("/linebot/new")}>
          新規作成
        </Button>
      </Box>

      {loading && <CircularProgress />} {/* スピナーの表示 */}

      <List>
  {lineBots.map((lineBot) => (
    <Box key={lineBot.id}>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={lineBot.name || "名称未設定"}
          secondary={
            <>
              <Typography variant="body2" color="text.secondary" noWrap>
                {lineBot.content?.substring(0, 50) || "内容がありません..."}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ステータス:
                {(() => {
                  switch (lineBot.status) {
                    case 1:
                      return "オープン";
                    case 2:
                      return "クローズ";
                    case 3:
                      return "削除済み";
                    case 4:
                      return "審査中";
                    default:
                      return "不明";
                  }
                })()}
              </Typography>
            </>
          }
        />
        <IconButton edge="end" aria-label="edit" onClick={() => handleEditLineBot(lineBot.id)} disabled={loading}>
          <EditIcon />
        </IconButton>
        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteLineBot(lineBot.id)} disabled={loading}>
          <DeleteIcon />
        </IconButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </Box>
  ))}
</List>


      <Pagination
        count={Math.ceil(lineBots.length / itemsPerPage)}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 2 }}
      />

      {/* 成功・失敗の通知 */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={message}
      />
    </Box>
  );
};
