import { FC, useState, useEffect } from "react";
import { serverTimestamp } from "firebase/firestore";
import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  CircularProgress,
  Alert,
  Switch,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

if (!ENCRYPTION_KEY) {
  console.error("暗号化キーが設定されていません。REACT_APP_ENCRYPTION_KEY を確認してください。");
}

type Form = {
  name: string;
  memo: string;
  ragId: string;
  promptId: string;
  isOpen: boolean;
};

export const LinebotEditPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { userId } = useAppContext();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<Form>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [rags, setRags] = useState<any[]>([]);
  const [prompts, setPrompts] = useState<any[]>([]);
  const [secretId, setSecretId] = useState<string | null>(null);
  const [accessKey, setAccessKey] = useState<string | null>(null);
  const [webhookURL, setWebhookURL] = useState<string | null>(null);

  const decrypt = (encryptedText: string): string => {
    if (!ENCRYPTION_KEY) {
      throw new Error("暗号化キーが設定されていません。");
    }
    const bytes = CryptoJS.AES.decrypt(encryptedText, ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) {
          throw new Error("編集対象のIDが指定されていません");
        }

        const docRef = doc(db, "linebots", id);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          throw new Error("編集対象のデータが存在しません");
        }

        const linebotData = docSnap.data();
        setValue("name", linebotData.name || "");
        setValue("memo", linebotData.memo || "");
        setValue("ragId", linebotData.ragId || "");
        setValue("promptId", linebotData.promptId || "");
        setValue("isOpen", linebotData.isOpen || false);

        // Decrypt sensitive data
        if (linebotData.secretId) {
          setSecretId(decrypt(linebotData.secretId));
        }
        if (linebotData.accessKey) {
          setAccessKey(decrypt(linebotData.accessKey));
        }

        // Generate webhook URL
        setWebhookURL(`https://linebot.zero-one.llc/lineWebhook?id=${id}`);

        // Fetch related data
        const ragsRef = collection(db, "rags");
        const promptsRef = collection(db, "prompts");

        const userRagQuery = query(ragsRef, where("userId", "==", userId));
        const userPromptQuery = query(promptsRef, where("userId", "==", userId), where("type", "==", "chat"));

        const openRagQuery = query(ragsRef, where("isOpen", "==", true), where("type", "==", "chat"));
        const openPromptQuery = query(promptsRef, where("isOpen", "==", true), where("type", "==", "chat"));

        const [userRagSnap, userPromptSnap, openRagSnap, openPromptSnap] = await Promise.all([
          getDocs(userRagQuery),
          getDocs(userPromptQuery),
          getDocs(openRagQuery),
          getDocs(openPromptQuery),
        ]);

        const fetchedRags = [
          ...userRagSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          ...openRagSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ];
        const fetchedPrompts = [
          ...userPromptSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          ...openPromptSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ];

        setRags(fetchedRags);
        setPrompts(fetchedPrompts);
      } catch (error) {
        console.error("データの取得に失敗しました", error);
        setErrorMessage("データの取得中にエラーが発生しました。再試行してください。");
      }
    };

    fetchData();
  }, [id, userId, setValue]);

  const submit = async (data: Form) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      if (!id) {
        throw new Error("編集対象のIDが指定されていません");
      }

      const docRef = doc(db, "linebots", id);
      await updateDoc(docRef, {
        name: data.name,
        memo: data.memo,
        ragId: data.ragId,
        promptId: data.promptId,
        isOpen: data.isOpen,
        updatedAt: serverTimestamp(),
      });

      navigate("/linebots");
    } catch (error) {
      console.error("Linebotの更新に失敗しました", error);
      setErrorMessage("更新中にエラーが発生しました。再試行してください。");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submit)}
      sx={{
        p: 5,
        mt: 4,
        mx: "auto",
        maxWidth: "600px",
        border: "1px dashed grey",
        borderRadius: 2,
        backgroundColor: "#f7f7f7",
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h5" textAlign="center">
          AI Line Botを編集
        </Typography>

        {/* Name */}
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: "Line bot名は必須です" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Line bot名"
              fullWidth
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />
        {/* Secret ID (Read-only) */}
        {secretId && (
          <TextField
            label="シークレットID"
            value={secretId}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                color: "gray", // Change text color
                backgroundColor: "#f0f0f0", // Add a background color
              },
            }}
          />
        )}

        {/* Access Key (Read-only) */}
        {accessKey && (
          <TextField
            label="アクセスキー"
            value={accessKey}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                color: "gray", // Change text color
                backgroundColor: "#f0f0f0", // Add a background color
              },
            }}
          />
        )}

        {/* Webhook URL (Read-only) */}
        {webhookURL && (
          <TextField
            label="Webhook URL"
            value={webhookURL}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                color: "gray", // Change text color
                backgroundColor: "#f0f0f0", // Add a background color
              },
            }}
          />
        )}


        {/* Error Message */}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        {/* Submit Button */}
        <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : "更新する"}
        </Button>
      </Stack>
    </Box>
  );
};
